<template>
  <div class="full-height__ container_auth">
    <div class="d-flex justify-center mt-3">
      <img
        src="../../../assets/img/logo-tanoto.png"
        class="mr-2"
        alt="logo-tanoto-foundation"
        height="40px"
        style="z-index: 1"
      />
      <img
        src="../../../assets/img/logo-teladan.png"
        alt="logo-teladan"
        height="40px"
        style="z-index: 1"
      />
    </div>
    <div
      class="container_temp d-flex flex-column align-center justify-center pa-3"
    >
      <v-card class="temp__" elevation="5">
        <div class="py-7 px-7">
          <div class="d-flex justify-center">
            <img
              src="../../../assets/img/typeEmail.png"
              height="150px"
              alt=""
            />
          </div>
          <h2 style="color: #006341">Reset Password</h2>
          <p class="grey--text">
            Enter your registred email below to receive password reset
            instruction
          </p>
          <v-alert
            :value="response ? true : false"
            v-if="response.email"
            dense
            text
            transition="scale-transition"
            class="rounded_alert py-2 px-7"
            type="error"
          >
            <small>{{ response.email }}</small>
          </v-alert>
          <form @submit.prevent="reqLink">
            <v-text-field
              v-model="email"
              hide-details="auto"
              :error-messages="EmailError"
              label="Email"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
            <div class="mt-5">
              <v-btn
                class="text-capitalize"
                type="reqLink"
                block
                v-if="!loading"
                color="#006341"
                dark
              >
                Send
              </v-btn>
              <v-btn
                class="mb-3 text-capitalize"
                v-if="loading"
                block
                color="#006341"
                dark
              >
                Loading..
              </v-btn>
            </div>
          </form>
        </div>
        <v-card-actions class="footer">
          <section class="p-1 d-flex justify-center full_width__">
            <small class="white--text">
              <router-link class="white--text" to="/login"
                >Back to login page</router-link
              ></small
            >
          </section>
        </v-card-actions>
      </v-card>
    </div>
    <div class="temp-boba d-flex">
      <div class="boba1"></div>
      <div class="boba2"></div>
      <div class="boba3"></div>
      <div class="boba4"></div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  computed: {
    ...mapState({
      errMsg: (state) => state.auth.errMsg,
      response: (state) => state.auth.response,
    }),
    EmailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Email tidak valid!");
      !this.$v.email.required && errors.push("Email harus di isi!");
      return errors;
    },
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    this.$store.commit("auth/RESET_RESPONSE");
    next();
  },
  data() {
    return {
      email: "",
      errAlert: false,
      loading: false,
      err: null,
    };
  },
  methods: {
    reqLink() {
      let data = {
        email: this.email,
      };
      this.loading = true;
      this.$store
        .dispatch("auth/requestResetPassword", data)
        .then((data) => {
          if (data.data.status) {
            this.$router.push(`/sent/${this.email}`);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container_auth {
  height: 100vh;
  width: 100%;
  background: #006341;
  overflow: hidden;
}
.container_temp {
  top: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 2 !important;
}
.temp__ {
  width: 30%;
  height: max-content;
  background: white;
  border-radius: 20px !important;
  overflow: hidden;
}
.temp_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 57%;
}
.covers__ {
  background: #a2aaad;
  width: 43%;
  border-radius: 0 !important;
}
.footer {
  background: #b3a369;
  border-radius: 0 !important;
}
.temp-boba {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.rounded_alert {
  border-radius: 20px !important;
}
.boba1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 6s;
  transform-origin: bottom;
  animation-delay: 1s;
}
.boba2 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 7s;
  transform-origin: bottom;
  animation-delay: 2s;
}
.boba3 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 5s;
  transform-origin: bottom;
  animation-delay: 0.5s;
}
.boba4 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba4 infinite ease-in-out alternate 3s;
  transform-origin: bottom;
  animation-delay: 6s;
}

@keyframes boba1 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
@keyframes boba4 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(200%, 0%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
</style>
